<template>
    <header class="page-title">
        <h1><i class="bi bi-journal-text"></i> 日報登録</h1>
    </header>

    <section class="section">
        <form @submit.prevent="save">

        <div class="row mb-5 align-items-end">
            <div class="col-6">
                <label class="form-label">日付</label>
                <form-input-date v-model="report.report_date" required></form-input-date>
            </div>
            <div class="col-6">
                <label class="form-label">担当</label>
                <form-select-object v-if="$store.getters['auth/canEditOthersReport']()" v-model="report.user" :options="users" option_value="user_id" option_label="user_name" required :disabled="users.length === 0"></form-select-object>
                <div v-else class="form-readonly">{{ report.user.user_name }}</div>
            </div>
            <div class="col-12 text-end">
                <button type="button" class="btn btn-outline-primary" @click="getPrevious()">前日の明細をコピー</button>
            </div>
        </div>

        <div class="row border-bottom">
            <div class="col-6"><label class="form-label">案件</label></div>
            <div class="col-3"><label class="form-label">稼働時間</label></div>
            <div class="col-3"><label class="form-label">請求時間</label></div>
            <div class="col-5"><label class="form-label">フェーズ/作業カテゴリ</label></div>
            <div class="col-5"><label class="form-label">作業詳細</label></div>
            <div class="col-2"></div>
        </div>
        <div class="row py-3 border-bottom" v-for="work,i in report.works" :key="i">
            <div class="col-6">
                <form-select-object-search v-model="work.project" :options="projects" option_value="project_id" option_label="project_name" required :disabled="projects.length === 0"></form-select-object-search>
            </div>
            <div class="col-3">
                <form-input-minutes v-model="work.work_time" required></form-input-minutes>
            </div>
            <div class="col-3">
                <form-input-minutes v-model="work.charge_time" :disabled="!work.project || work.project.project_type !== ProjectType.TIME_AND_MATERIAL"></form-input-minutes>
            </div>
            <div class="col-5">
                <form-select v-if="!work.project || !work.project.project_id" :disabled="true"></form-select>
                <form-select-object v-else-if="work.project.project_type === ProjectType.ONE_OFF" v-model="work.phase" :options="work.project.phases" option_value="phase_id" option_label="phase_name" empty_option="-- フェーズ --" required></form-select-object>
                <form-select-object v-else v-model="work.work_category" :options="work.project.work_categories" option_value="work_category_id" option_label="category_name" empty_option="-- 作業カテゴリ --" required></form-select-object>
            </div>
            <div class="col-5">
                <form-input v-model="work.work_note" required></form-input>
            </div>
            <div class="col-2">
                <button type="button" class="btn btn-outline-secondary" @click="removeRow(i)"><i class="bi bi-x"></i></button>
            </div>
        </div>
        <div class="row py-3">
            <div class="col-6"></div>
            <div class="col-3">計: {{ $helper.clock(total_work_time) }}</div>
            <div class="col-3"></div>
            <div class="col-5"></div>
            <div class="col-5"></div>
            <div class="col-2">
                <button type="button" class="btn btn-outline-primary" @click="addRow()"><i class="bi bi-plus"></i> 追加</button>
            </div>
        </div>

        <div class="mt-5">
            <button type="submit" class="btn btn-lg btn-info">保存</button>
        </div>

        </form>
    </section>
</template>

<script>
import FormInput from '@/components/forms/FormInput';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputMinutes from '@/components/forms/FormInputMinutes';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import Project from "@/models/entities/project";
import Report from "@/models/entities/report";
import User from "@/models/entities/user";
import Work from "@/models/entities/work";
import ProjectType from "@/models/enums/project-type";

export default {
    name: 'ReportAdd',
    components: {
        FormInput,
        FormInputDate,
        FormInputMinutes,
        FormSelect,
        FormSelectObject,
        FormSelectObjectSearch,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            report: new Report({
                report_date: this.$route.query.report_date ?? this.$helper.today(),
                user: new User({
                    user_id: this.$store.state.auth.user_id,
                    user_name: this.$store.state.auth.user_name
                }),
                works: [new Work()]
            }),
            users: [],
            projects: [],
            ProjectType,
        }
    },
    mounted() {
        this.fetchMaster();
    },
    computed: {
        total_work_time() {
            return this.report.works.reduce((sum, work) => sum + (this.$helper.isBlank(work.work_time) ? 0 : work.work_time), 0);
        }
    },
    methods: {
        fetchMaster()
        {
            this.$http.get('/list/user', {params: {month_from: this.report.report_date_month, month_to: this.report.report_date_month}})
            .then((response) => {
                this.users = response.data.map((row) => {return new User(row)});
            });
            this.$http.get('/list/project', {params: {month_from: this.report.report_date_month, month_to: this.report.report_date_month}})
            .then((response) => {
                this.projects = response.data.map((row) => {return new Project(row)});
            });
        },
        addRow()
        {
            this.report.works.push(new Work());
        },
        removeRow(index)
        {
            this.report.works.splice(index, 1);
        },
        save() {
            this.startScreenLoading();

            this.$http.post('/report', this.report)
            .then((response) => {
                this.showMessage('登録しました');
                this.$router.push({name: 'ReportEdit', params: {report: response.data.report_id}});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        getPrevious() {
            this.startScreenLoading();

            this.$http.get(`/report/${this.report.report_date}/previous`)
            .then((response) => {
                response.data.works.forEach((row) => {
                    this.report.works.push(new Work(row));
                })
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>
</style>

<style>
    .multiselect-fake-input {bottom: 0;}
</style>
